import React, {useRef, useState} from 'react';
import LayoutStore from '../layouts/store/_layout';
import {Link} from "gatsby";
import { CartContext } from '../context/CartContext';
import Slider from "react-slick";
import {SlickCustomDots} from "../components/shared"; 
import IcoBag from "../static/images/icons/bag.inline.svg";
import {CartController} from "../helpers/CartController";

const ProductDetailPage = ({pageContext}) => {
  const [ cartCount, setCartCount ] = useState(CartController.getOrCreateCartIfNoExits('es').items.length);

  const updateCartCount = () => {
    setCartCount(CartController.getOrCreateCartIfNoExits('es').items.length);
  }

  const { data, list } = pageContext;
  let masProductos = [...list];
  masProductos = masProductos.filter(el => !el.link.match(/^(\/en\/|en\/)/i));
  const finalGal = [...data.galleryImages.nodes];
  finalGal.unshift(data.image);
  const [quantity, setQuant] = useState(1);
  const [gallery] = useState(finalGal);
  const sliderRef = useRef();
  const settingsRelated = {
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    dots: false,
    gap: '32px',
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          arrows: false,
          initialSlide: 1,
        }
      }
    ]
  };
  settingsRelated.slidesToShow = (masProductos.length < 5) ? masProductos.length : 5;
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    dots: true,
    appendDots: dots => {
      return (<SlickCustomDots dots={dots} gallery={gallery} featured={data.image} slider={sliderRef} singleProduct={true}/>);
    },
  };

  const addToCart = () => {
    CartController.addToCart(data, quantity, 'es');
    updateCartCount();
  }

  const quantityLess = () => {
    setQuant((quantity - 1) > 0 ? quantity - 1 : 1 );
  }

  const quantityMore = () => {
    setQuant( (quantity + 1) <= data.stockQuantity ? quantity + 1 : quantity );

  }

  return (
    <LayoutStore pageId="product-detail">
      <CartContext.Consumer>
        {({ setShowShoppingCart }) => (
        <div className="container">
          <div className="move-to-container">
            <div className="to-back">
              <Link className="btn btn--back" to="/tienda">
                <i className="icon icon-arrow-left--gray"></i>
                Regresar
              </Link>
            </div>

            <div className="navigate">
              <button
                className="cartButton"
                type="button"
                onClick={() => setShowShoppingCart((prev) => !prev)}>
                  <span className="ico-box">
                    <IcoBag />
                    {cartCount !== 0 ? (<span className="quantity">{cartCount}</span>) : (<></>)}
                  </span>
              </button>
            </div>
          </div>
         
          <div className="row detail-row">
            <div className="col-lg-4 information">
              <div className="content">
                <h1>{data.name}</h1>
                <p className="price" >
                  <span dangerouslySetInnerHTML={{__html: data.price}}></span>
                  {' '}
                </p>
                <p className="description" dangerouslySetInnerHTML={{__html: data.content}}>
                </p>
                {(data.stockQuantity) ? (
                  <p className="stock">
                    *Stock disponible: {data.stockQuantity} Unidades
                  </p>
                ) : (<></>) }
                <div className="controls-buttons">
                  <div className="quantity-controls">
                    <button className="btn btn--primary quantity-button" onClick={quantityLess}>
                      -
                    </button>
                    <input type="text" disabled="true" className="quantity-field" value={quantity}/>
                    <button className="btn btn--primary quantity-button" onClick={quantityMore}>
                      +
                    </button>

                  </div>
                  <button
                    onClick={addToCart}
                    className="btn btn--primary btn-add-to-cart"
                    type="button">
                    Agregar a mi Bolsa
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-8 image">
              <Slider {...settings} ref={sliderRef}>
                { gallery ?
                  (gallery.map((v, index) => (
                    <picture key={index}>
                      <img src={v.sourceUrl} alt={`Gallery Image (${index}) of ${data.title}`}/>
                    </picture>
                  ))) : (<></>)
                }
              </Slider>
            </div>
          </div>
          <div className="row related-row">
            <div className="col-lg-4 related-colum">
              <h2 className="related-title">
                Más productos
              </h2>
            </div>
            <div className="col-lg-12">
              <Slider {...settingsRelated}>
                {
                  masProductos.map(el => (
                    <Link to={`/tienda/${el.slug}`} className={`${el.stockStatus === 'OUT_OF_STOCK' ? 'sold-out related-card' : 'related-card'}`}>
                      <img src={el.image.sourceUrl} alt={el.name} className="relate-prod-img"/>
                    </Link>
                  ))
                }
              </Slider>
            </div>
          </div>
        </div>
        )}
      </CartContext.Consumer>
    </LayoutStore>
  );
};

export default ProductDetailPage;
